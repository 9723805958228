import { handleResponseStatus, handleServerError, createError } from 'helpers/errors';
import {
  getUsers,
  getUser,
  getUserSummary,
  deleteUser,
  createUser,
  updateUser,
  updateMe,
  loginUser,
  sendPasswordResetEmail,
  resetPassword,
  contactUser,
  validateNewUser,
  verifyUserCredentials,
  sendMagicLinkEmail,
} from 'requests/users';
import { userUpdateSuccess } from './User';

export function usersFetchSuccess(users) {
  return {
    type: 'USERS_FETCH_SUCCESS',
    users,
  };
}

export function userCreateAppend(user) {
  return {
    type: 'USER_CREATE_SUCCESS',
    user,
  };
}

export function userDeleteSuccess(userId) {
  return {
    type: 'USER_DELETE_SUCCESS',
    userId,
  };
}

export const passwordReset = (url, values) => async () => {
  try {
    const response = await resetPassword(url, values);

    handleResponseStatus(response, 204);

    return response;
  } catch (error) {
    throw handleServerError(error, 'passwordReset Action');
  }
};

export const passwordResetEmail = values => async () => {
  try {
    const response = await sendPasswordResetEmail(values);

    handleResponseStatus(response, 204);

    return response;
  } catch (error) {
    throw handleServerError(error, 'passwordResetEmail Action');
  }
};

export const magicLinkEmail = values => async () => {
  try {
    const response = await sendMagicLinkEmail(values);

    handleResponseStatus(response, 204);

    return response;
  } catch (error) {
    throw handleServerError(error, 'magicLinkEmail Action');
  }
};

/**
 * @param number pageSize
 * @param number page
 */
export const usersFetchPage = (queryParams = {}) => async () => {
  const response = await getUsers(queryParams);
  const { count, results, next, previous } = response.data;
  const usersById = results.reduce((previous, current) => ({
    ...previous,
    [current.id]: current,
  }), {});

  handleResponseStatus(response, 200);

  return { count, next, previous, results: usersById };
};

export const usersFetch = (queryParams = {}) => async (dispatch) => {
  let allUsers = {};
  const pageSize = 30;

  try {
    const { count, results: initialUsers } = (
      await dispatch(usersFetchPage({ page_size: pageSize, page: 1, ...queryParams }))
    );

    allUsers = initialUsers;

    if (count > pageSize) {
      const numRemainingPages = Math.ceil((count - pageSize) / pageSize);
      const pages = Array.from({ length: numRemainingPages }, (_, idx) => idx + 2);

      await Promise.all(pages.map(async (page) => {
        const { results } = await dispatch(usersFetchPage(
          { page_size: pageSize, page, ...queryParams },
        ));
        allUsers = { ...allUsers, ...results };
      }));
    }

    dispatch(usersFetchSuccess(allUsers));

    return allUsers;
  } catch (error) {
    throw handleServerError(error, 'usersFetch Action');
  }
};

export const userFetch = userId => async (dispatch) => {
  try {
    const response = await getUser(userId);

    handleResponseStatus(response, 200);

    dispatch(userCreateAppend(response.data));
    return response;
  } catch (error) {
    throw handleServerError(error, 'userFetch Action');
  }
};

export const userSummaryFetch = userId => async () => {
  try {
    const response = await getUserSummary(userId);

    handleResponseStatus(response, 200);

    return response.data;
  } catch (error) {
    throw handleServerError(error, 'userSummaryFetch Action');
  }
};

export const userDelete = userId => async (dispatch) => {
  try {
    const response = await deleteUser(userId);

    handleResponseStatus(response, 204);

    dispatch(userDeleteSuccess(userId));
    return response;
  } catch (error) {
    throw handleServerError(error, 'userDelete Action');
  }
};

export const userCreate = data => async (dispatch) => {
  try {
    const response = await createUser(data);

    handleResponseStatus(response, 201);

    dispatch(userCreateAppend(response.data));
    return response.data;
  } catch (error) {
    throw handleServerError(error, 'userCreate Action');
  }
};

export const userUpdate = (userId, data) => async (dispatch) => {
  try {
    const response = await updateUser(userId, data);

    handleResponseStatus(response, 200);

    dispatch(userCreateAppend(response.data));
    return response.data;
  } catch (error) {
    throw handleServerError(error, 'userUpdate Action');
  }
};

export const setUserPassword = (currentPassword, newPassword) => async (dispatch) => {
  try {
    const response = await updateMe({ current_password: currentPassword, password: newPassword });

    handleResponseStatus(response, 200);
    dispatch(userUpdateSuccess(response.data));

    return response;
  } catch (error) {
    throw handleServerError(error, 'setUserPassword Action');
  }
};

export const userLogin = data => async () => {
  try {
    const response = await loginUser(data);

    handleResponseStatus(response, 200);

    const _data = response.data || {}; // eslint-disable-line no-underscore-dangle

    if (_data.error_code !== undefined) {
      throw createError(_data.error, 'authentication');
    }

    return response;
  } catch (error) {
    throw handleServerError(error, 'userLogin Action');
  }
};

export const userVerifyCredentials = data => async () => {
  try {
    const response = await verifyUserCredentials(data);

    handleResponseStatus(response, 200);

    const _data = response.data || {}; // eslint-disable-line no-underscore-dangle

    if (_data.valid === false) {
      throw createError(_data.error, 'authentication');
    }

    return response;
  } catch (error) {
    throw handleServerError(error, 'userVerifyCredentials Action');
  }
};

export const userContact = data => async () => {
  try {
    const response = await contactUser(data);

    handleResponseStatus(response, 200);

    return response;
  } catch (error) {
    throw handleServerError(error, 'userContact Action');
  }
};

export const newUserValidate = data => async () => {
  try {
    const response = await validateNewUser(data);

    handleResponseStatus(response, 200);

    return response;
  } catch (error) {
    throw handleServerError(error, 'newUserValidate Action');
  }
};
