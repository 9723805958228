/* eslint-disable import/first */
// eslint-disable-next-line no-unused-vars
/* global config, __webpack_public_path__ */

/*
  This tells webpack's runtime to grab dynamic imports from our static assets path
*/
// eslint-disable-next-line no-global-assign
__webpack_public_path__ = config.STATIC_JS_APP_BASE_URL;

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import { initAnalytics } from 'helpers/analytics';
import { createBatonStore } from 'helpers/redux';

import AnalyticsRouteTracker from 'components/AnalyticsRouteTracker';
import AppConfetti from 'components/Confetti/AppConfetti';
import ErrorBoundary from 'components/Error/ErrorBoundary';
import SetupRoutes from 'components/Routes/Setup';

import theme from 'theme';

const store = createBatonStore();

initAnalytics();

ReactDOM.render(
  (
    <ErrorBoundary>
      <StyledEngineProvider injectFirst>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <AppConfetti />
            <BrowserRouter>
              <AnalyticsRouteTracker />
              <SetupRoutes />
            </BrowserRouter>
          </ThemeProvider>
        </Provider>
      </StyledEngineProvider>
    </ErrorBoundary>
  ),
  document.getElementById('setup'),
);
