import { forwardRef } from 'react';

import type { SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement> & {
  color?: string
  horizontal?: boolean
  strokeWidth?: number
};

const More = forwardRef<SVGSVGElement, Props>((props, ref) => {
  const { color, horizontal, strokeWidth, ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      style={{
        transformOrigin: 'center',
        transition: 'transform 0.25s',
        transform: `rotate(${horizontal ? '90deg' : '0deg'})`,
      }}
      ref={ref}
      {...otherProps}
    >
      <circle
        cx={12}
        cy={6}
        fill={color}
        r={1.5}
        strokeWidth={strokeWidth}
      />
      <circle
        cx={12}
        cy={12}
        fill={color}
        r={1.5}
        strokeWidth={strokeWidth}
      />
      <circle
        cx={12}
        cy={18}
        fill={color}
        r={1.5}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
});

More.defaultProps = {
  color: 'currentColor',
  horizontal: false,
  strokeWidth: 1,
};

More.displayName = 'More';

export default More;
