import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const Visibility = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color, strokeWidth, ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <path
        d="M20.397 11.953c-.09.124-.212.287-.368.477-.371.453-.926 1.06-1.656 1.665C16.912 15.307 14.77 16.5 12 16.5c-2.772 0-4.913-1.193-6.374-2.405a12.356 12.356 0 0 1-1.655-1.665c-.156-.19-.279-.353-.368-.477a9.17 9.17 0 0 1 .342-.457c.354-.444.887-1.038 1.602-1.632C6.975 8.678 9.117 7.5 12 7.5c2.884 0 5.025 1.178 6.453 2.364a11.271 11.271 0 0 1 1.602 1.632c.145.181.259.337.342.457Z"
        stroke={color}
        strokeMiterlimit={10}
        strokeWidth={strokeWidth}
      />
      <path
        d="M12 16.5a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9Z"
        stroke={color}
        strokeMiterlimit={10}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
});

Visibility.defaultProps = {
  color: 'currentColor',
  strokeWidth: 1,
};

Visibility.displayName = 'Visibility';

export default Visibility;
