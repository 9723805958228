import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

import Typography from 'components/ui/Atoms/Typography';
import Button from 'components/Buttons/Button';
import useFormikExtensions from 'components/Forms/useFormikExtensions';
import LoginHeader from './LoginHeader';
import TextInput from '../../Inputs/TextInput';
import styles from './LoginStyles';

const useStyles = makeStyles({ name: 'LoginForm' })(styles);

const Form = (props) => {
  // Props
  const { formError } = props;

  // Hooks
  const {
    getFieldPropsWithError,
    handleSubmit,
    isSubmitting,
    hasVisibleErrors,
  } = useFormikExtensions();
  const { classes } = useStyles();

  return (
    <form onSubmit={handleSubmit} data-cy="login-form">
      <LoginHeader className={classes.header} />
      <div className={classes.content}>
        <TextInput
          {...getFieldPropsWithError('email')}
          id="email"
          label="Email"
          placeholder="Email address"
          type="email"
        />
        <Typography
          variant="body1"
          component="span"
          className={classes.error}
        >
          {formError}
        </Typography>
        <Button
          disabled={isSubmitting || hasVisibleErrors}
          className={classes.submitButton}
          type="submit"
          data-cy="setup-login-button"
        >
          { isSubmitting ? 'Signing in...' : 'Sign in' }
        </Button>
      </div>
    </form>
  );
};

Form.defaultProps = {
  formError: '',
};

Form.propTypes = {
  formError: PropTypes.string,
};

export default Form;
