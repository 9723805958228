import { forwardRef } from 'react';

import type { SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement> & {
  color?: string
  small?: boolean
  direction?: 'up' | 'down' | 'left' | 'right'
  strokeWidth?: number
};

const rotationMap = {
  up: 180,
  down: 0,
  left: 90,
  right: -90,
};

const Chevron = forwardRef<SVGSVGElement, Props>((props, ref) => {
  const { color, small, direction, strokeWidth, ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      style={{
        transformOrigin: 'center',
        transition: 'transform 0.25s',
        transform: `rotate(${rotationMap[direction]}deg)`,
      }}
      ref={ref}
      {...otherProps}
    >
      <path
        d={small ? 'M16 10l-3.996 4L8 10' : 'M18 9l-5.994 6L6 9'}
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
});

Chevron.defaultProps = {
  direction: 'down',
  color: 'currentColor',
  small: false,
  strokeWidth: 1,
};

Chevron.displayName = 'Chevron';

export default Chevron;
