import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

import useFormikExtensions from 'components/Forms/useFormikExtensions';
import Typography from 'components/ui/Atoms/Typography';
import Button from 'components/Buttons/Button';
import styles from './SignupStyles';
import TextInput from '../../Inputs/TextInput';
import PasswordInputWithStrength from '../../Inputs/PasswordInput/PasswordInputWithStrength';

const useStyles = makeStyles({ name: 'SignupForm' })(styles);

const SignupForm = (props) => {
  // Props
  const {
    title,
    fqdn,
    submitText,
    formError,
  } = props;

  // Hooks
  const {
    getFieldPropsWithError,
    handleSubmit,
    isSubmitting,
    hasVisibleErrors,
  } = useFormikExtensions();
  const { classes } = useStyles();

  return (
    <form onSubmit={handleSubmit} data-cy="signup-form">
      <Typography
        variant="display4"
        component="h1"
        className={classes.heading}
      >
        {title}
      </Typography>
      <Typography
        variant="body2"
        component="span"
        className={classes.url}
      >
        {fqdn}
      </Typography>
      <div className={classes.splitInputContainer}>
        <div className={classes.splitInput}>
          <TextInput
            {...getFieldPropsWithError('first_name')}
            aria-label="First name"
            id="first_name"
            placeholder="First name"
          />
        </div>
        <div className={classes.splitInput}>
          <TextInput
            {...getFieldPropsWithError('last_name')}
            id="last_name"
            aria-label="Last name"
            placeholder="Last name"
          />
        </div>
      </div>
      <TextInput
        {...getFieldPropsWithError('job_title')}
        aria-label="Job title"
        id="job_title"
        placeholder="Title eg. Head of Customer Success"
      />
      <PasswordInputWithStrength
        {...getFieldPropsWithError('password')}
      />
      <div className={classes.submitPanel}>
        <Typography
          variant="body1"
          component="span"
          className={classes.error}
        >
          {formError}
        </Typography>
        <Button
          disabled={isSubmitting || hasVisibleErrors}
          className={classes.submitButton}
          type="submit"
          data-cy="setup-submit-button"
        >
          {submitText}
        </Button>
        <Typography
          variant="body1"
          className={classes.footer}
        >
          By continuing you’re agreeing to our&nbsp;
          <a rel="noopener noreferrer" target="_blank" href="http://hellobaton.com/terms">User Terms of Service</a>,
          <br />
          <a rel="noopener noreferrer" target="_blank" href="http://hellobaton.com/privacy">Privacy Policy</a>
          &nbsp;and&nbsp;
          <a rel="noopener noreferrer" target="_blank" href="http://hellobaton.com/privacy">Cookie Policy</a>.
        </Typography>
      </div>
    </form>
  );
};

SignupForm.defaultProps = {
  formError: null,
  fqdn: null,
};

SignupForm.propTypes = {
  submitText: PropTypes.string.isRequired,
  title: PropTypes.node.isRequired,
  formError: PropTypes.string,
  fqdn: PropTypes.string,
};

export default SignupForm;
