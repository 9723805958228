import React from 'react';
import PropTypes from 'prop-types';

function Wordmark({ color, ...props }) {
  return (
    <svg width={120} height={24} viewBox="0 0 120 24" fill="none" {...props}>
      <g clipPath="url(#clip0)">
        <path
          d="M84.1538 0C89.3476 0 93.5551 2.745 93.5551 7.24125V15.9488C93.5551 20.4488 89.3476 23.2238 84.1538 23.2238C78.8926 23.2238 74.6851 20.4488 74.6851 15.9488V7.24125C74.6851 2.745 78.8926 0 84.1538 0ZM84.1538 19.5863C86.5988 19.5863 88.6013 18.1838 88.6013 15.5663V7.65375C88.6013 5.00625 86.5988 3.63375 84.1538 3.63375C81.6413 3.63375 79.6726 5.00625 79.6726 7.65375V15.5663C79.6726 18.1838 81.6413 19.5863 84.1538 19.5863Z"
          fill={color}
        />
        <path
          d="M43.6388 17.385H35.79C35.67 17.385 35.565 17.4562 35.5275 17.565L33.7838 22.5975C33.7463 22.7025 33.6413 22.7775 33.5213 22.7775H28.9238C28.7325 22.7775 28.5975 22.5975 28.665 22.4287L37.32 0.614978C37.3613 0.513728 37.4625 0.446228 37.5788 0.446228H41.9138C42.03 0.446228 42.1313 0.513728 42.1725 0.614978L50.8575 22.4287C50.925 22.5975 50.79 22.7775 50.5988 22.7775H45.9675C45.8475 22.7775 45.7425 22.7062 45.705 22.6012L43.8975 17.5612C43.8638 17.4562 43.755 17.385 43.6388 17.385ZM42.4913 13.5975L40.0725 6.85498C39.93 6.46123 39.3375 6.46498 39.1988 6.86248L36.8888 13.6012C36.8325 13.77 36.9638 13.9387 37.1513 13.9387H42.2288C42.42 13.9425 42.5513 13.7662 42.4913 13.5975Z"
          fill={color}
        />
        <path
          d="M21.24 11.4863C22.8675 10.6238 23.7188 8.80501 23.7188 7.43626V6.48001C23.7188 2.49376 20.8013 0.450012 15.4725 0.450012H6.37503C6.22128 0.450012 6.09753 0.566262 6.09753 0.708762V7.44751C6.09753 7.59001 6.22128 7.70626 6.37503 7.70626H10.7775C10.9313 7.70626 11.055 7.59001 11.055 7.44751V4.31251C11.055 4.17001 11.1788 4.05376 11.3325 4.05376H15.5363C17.7413 4.05376 18.7275 4.78876 18.7275 6.89251V7.21126C18.7275 9.22126 17.7788 10.1138 16.35 10.1138H11.055H1.38753C1.27128 10.1138 1.17003 10.1813 1.12878 10.2825L0.0187815 13.0838C-0.0487185 13.2525 0.0862815 13.4325 0.277531 13.4325H11.0588H16.6575C18.39 13.4325 19.305 14.4863 19.305 16.08V16.6875C19.305 18.1538 18.4238 19.2075 16.3163 19.2075H11.3325C11.1788 19.2075 11.055 19.0913 11.055 18.9488V16.1025C11.055 15.96 10.9313 15.8438 10.7775 15.8438H6.37503C6.22128 15.8438 6.09753 15.96 6.09753 16.1025V22.5225C6.09753 22.665 6.22128 22.7813 6.37503 22.7813H16.3838C21.78 22.7813 24.2588 20.355 24.2588 17.04V15.9225C24.2588 13.8788 22.8675 12.1238 21.24 11.4863Z"
          fill={color}
        />
        <path
          d="M70.3987 0.446228H54.5962C54.48 0.446228 54.3787 0.513728 54.3375 0.614978L53.1112 3.70123C53.0437 3.86998 53.1788 4.04998 53.37 4.04998H58.8562C59.01 4.04998 59.1337 4.16623 59.1337 4.30873V22.5187C59.1337 22.6612 59.2575 22.7775 59.4112 22.7775H63.8137C63.9675 22.7775 64.0912 22.6612 64.0912 22.5187V4.30873C64.0912 4.16623 64.215 4.04998 64.365 4.04998H70.3987C70.5525 4.04998 70.6762 3.93373 70.6762 3.79123V0.704978C70.6725 0.562478 70.5487 0.446228 70.3987 0.446228Z"
          fill={color}
        />
        <path
          d="M119.723 0.446228H115.354C115.2 0.446228 115.076 0.562478 115.076 0.708728L115.196 13.4025C115.2 13.7587 114.701 13.8862 114.51 13.5787L106.47 0.573728C106.421 0.494978 106.331 0.446228 106.23 0.446228H104.966H98.8125C98.6963 0.446228 98.595 0.513728 98.5538 0.614978L97.4438 3.41623C97.3763 3.58498 97.5113 3.76498 97.7025 3.76498H101.198V22.5187C101.198 22.6612 101.321 22.7775 101.475 22.7775H105.776C105.93 22.7775 106.054 22.6612 106.054 22.515L105.934 9.87748C105.93 9.52498 106.429 9.39373 106.62 9.70123L114.668 22.6462C114.716 22.725 114.806 22.7737 114.904 22.7737H119.73C119.884 22.7737 120.008 22.6575 120.008 22.515V0.704978C120 0.562478 119.876 0.446228 119.723 0.446228Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="120" height="23.2238" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
}

Wordmark.defaultProps = {
  color: 'currentColor',
};

Wordmark.propTypes = {
  color: PropTypes.string,
};

export default Wordmark;
