import queryString from 'query-string';
import request, { migrateResponseConfig } from './setup';

export const getUser = userId => request.get(`/v1/users/${userId}/`);

export const createUser = data => request.post('/v1/users/', data);

export const updateUser = (userId, data) => request.patch(`/v1/users/${userId}/`, data);

export const deleteUser = userId => request.delete(`/v1/users/${userId}/`);

export const getUserSummary = userId => request.get(`/v1/users/${userId}/summary/`);

export const getUsers = (params = {}) => (
  request.get('/v1/users/', { params, ...migrateResponseConfig })
);

export const updateMe = data => request.patch('/v1/me/', data);

export const loginUser = data => request.post('/v1/token/', data);

export const checkUserSSOStatus = params => request.get('/auth/sso/check_email/', { params });

export const sendPasswordResetEmail = (data) => {
  const qs = queryString.stringify(data);
  const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };

  return request.post('/account/reset_password', qs, { headers });
};

export const resetPassword = (url, data) => {
  const body = { new_password1: data.password, new_password2: data.password };
  const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };

  return request.post(url, queryString.stringify(body), { headers });
};

export const contactUser = data => request.post(`/v1/users/${data.recipient}/contact/`, data);

// Used when creating a project to ensure user is not part of another org
export const validateNewUser = data => request.post('/v1/validation/newuser', data);

export const verifyUserCredentials = data => request.post('/v1/auth/', { ...data, verify_only: true });

export const sendMagicLinkEmail = data => request.post('/v1/magic_link/', data);

export const createVisualCue = data => (
  request.post('/v1/cues/', data) // user_id: visual_cue id
);

// Used as first step in workspace creation
export const verifyUser = data => request.post('/v1/user-initiate', data);

export const sendFindWorkspacesEmail = data => request.post('/v1/workspaces/find', data);

export const getNotificationPreferences = () => request.get('/v1/me/notification-preferences/');

export const putNotificationPreference = (key, enabled) => (
  request.put(`/v1/me/notification-preferences/${key}/`, { enabled })
);

export const getNotificationPreferenceCadence = key => (
  request.get(`/v1/me/notification-preferences-cadence/${key}/`)
);

export const putNotificationPreferenceCadence = (key, data) => (
  request.put(`/v1/me/notification-preferences-cadence/${key}/`, data)
);
