import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import ButtonBase from '@mui/material/ButtonBase';

import Typography from 'components/ui/Atoms/Typography';
import { trackEvent } from 'helpers/analytics';
import styles from './EmailVerificationStyles';

const useStyles = makeStyles({ name: 'Confirmation' })(styles);

function Confirmation(props) {
  const { email, isNewUser } = props;
  const { classes } = useStyles();

  const tryAgain = () => {
    const eventDescription = isNewUser ? 'Workspace Create Try Again' : 'Workspace Find Try Again';
    trackEvent(eventDescription, { email });
    window.location.reload();
  };

  return (
    <div data-cy="email-verification-confirmation">
      <Typography
        variant="display4"
        component="h1"
        className={classes.heading}
      >
        Email sent!
      </Typography>
      <Typography
        variant="paragraph"
        className={classes.text}
      >
        We sent a confirmation email to <strong>{email}</strong>.<br />
        Follow the email’s instructions to get started.
      </Typography>
      <div className={classes.submitPanel}>
        <Typography variant="caption2" className={classes.submitText}>
          Didn’t get an email?&nbsp;
          <ButtonBase
            className={classes.link}
            onClick={tryAgain}
          >
            Try again
          </ButtonBase>
        </Typography>
      </div>
    </div>
  );
}

Confirmation.propTypes = {
  email: PropTypes.string.isRequired,
  isNewUser: PropTypes.bool,
};

Confirmation.defaultProps = {
  isNewUser: false,
};

export default Confirmation;
