/* global Intercom */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

import IconButton from 'components/Buttons/IconButton';
import CircleQuestion from 'components/ui/Atoms/Icons/CircleQuestion';
import styles from './HelpButtonStyles';

const useStyles = makeStyles({ name: 'HelpButton' })(styles);

function HelpButton({ className }) {
  const { classes } = useStyles();
  const openWidget = () => {
    // Note: Closing is handled exclusively by the widget
    // https://developers.intercom.com/installing-intercom/docs/intercom-javascript
    Intercom('show');
  };

  return (
    <IconButton
      label="Help Widget"
      data-cy="help-widget"
      Icon={CircleQuestion}
      onClick={openWidget}
      iconClassName={classes.button}
      className={className}
    />
  );
}

HelpButton.defaultProps = {
  className: undefined,
};

HelpButton.propTypes = {
  className: PropTypes.string,
};

export default HelpButton;
